import { useState, useEffect, createRef } from 'react';

const areImagesLoaded = (image_els: any) => {
  return (
    image_els.filter(
      (image_el: any) => image_el.complete && image_el.naturalWidth && image_el.naturalWidth > 0
    ).length === image_els.length
  );
};

const useWaitingAnimation = (images_list: string[], delay: number, skip_first_check?: boolean) => {
  const [waiting, setWaiting] = useState(true);
  const [imageRefs, setImageRefs] = useState([]);

  const images_length = images_list.length;

  useEffect(() => {
    setImageRefs(imageRefs =>
      Array(images_length)
        .fill()
        .map((_, i) => imageRefs[i] || createRef())
    );
  }, [images_length]);

  useEffect(() => {
    if (!window) return; // SSR check
    if (imageRefs.length) {
      const checkImages = () => {
        const image_elements = imageRefs.map(imageRef => imageRef.current).slice(0, images_length);

        const status = areImagesLoaded(image_elements);
        
        if (!status) {
          setTimeout(checkImages, delay);
        } else {
          setWaiting(false);
        }
      };
      if(skip_first_check) {
        setTimeout(checkImages, delay);  
      } else {
        checkImages();
      }
      
    }
  }, [imageRefs]);

  return { imageRefs, waiting };
};

export default useWaitingAnimation;
