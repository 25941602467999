import React from 'react';

import SlidesNavigation from './SlidesNavigation';

interface ISlidesProps {
  slidesLabel: string[];
  currIndex: number;
  setCurrIndex: any;
  setPrevIndex: any;
  actual: number;
  normal: boolean;
}

const Slides: React.FC<ISlidesProps> = ({ slidesLabel, currIndex, setCurrIndex, setPrevIndex, actual, normal }) => {
	return (
		<nav className={normal ? 'slider-nav slider-nav--white' : 'slider-nav'}>
			<SlidesNavigation slidesLabel={slidesLabel} currIndex={currIndex} setCurrIndex={setCurrIndex} setPrevIndex={setPrevIndex} actual={actual} />
		</nav>
	);
};

export default Slides;
