import React from 'react';

interface IWaitForImagesProps {
  forwardedRef: any;
  url: any;
}

const WaitForImages: React.FC<IWaitForImagesProps> = ({ url, forwardedRef }) => {
  return (
    <div style={{ display: 'none' }}>
      <img src={url} alt="Slide" ref={forwardedRef} />
    </div>
  );
};

export default WaitForImages;
