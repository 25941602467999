import React from 'react';

interface ISlidesNavigationProps {
  slidesLabel: string[];
  currIndex: number;
  setCurrIndex: any;
  setPrevIndex: any;
  actual: number;
}

const SlidesNavigation: React.FC<ISlidesNavigationProps> = ({ slidesLabel, currIndex, setCurrIndex, setPrevIndex, actual }) => {
  return (
    <>
    	<ul className="slider-nav__list">
			{slidesLabel.map((label, index) => (
				<li key={index} className={actual === index ? 'slider-nav__item active' : 'slider-nav__item'} onClick={
          () => {
            setPrevIndex(currIndex);
            setCurrIndex(index);
          }
        }>
					<span className="slider-nav__title">{label}</span>
				</li>
			))}
		</ul>
    </>
  );
};

export default SlidesNavigation;
