import React, { Fragment, useEffect, useReducer, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import { gsap, Power3 } from 'gsap';

import Header from '@components/Header';
import Slides from '@components/Slides';
import HomePageLayout from '@components/HomePageLayout';
import Loader from '@components/Loader';
import WaitForImages from '@components/WaitForImages';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { TGatsbyImage } from '@utils/types';
import { extractGraphqlData, extractSlidesLabel } from '@utils/extractors';
import useWaitingAnimation from '@hooks/useWaitingAnimation';

type THomePage = {
  allHomepage_slides: {
    edges: { node: any }[];
  };
};

type THomePageData = {
  prismic: THomePage;
  logo: TGatsbyImage;
  menu: TGatsbyImage;
};

type TActionNormalType = {
  type: 'WHITE' | 'BLACK';
};

const LOADED_PAGE_DELAY = 1000;
const ANIMATION_TIME = 3000;

const useNormalStatus = () => {
  let [normal, changeNormal] = useReducer(
    (normal: any, action: TActionNormalType) => {
      switch (action.type) {
        case 'WHITE':
          return {
            ...normal,
            status: true,
          };
        case 'BLACK':
          return {
            ...normal,
            status: false,
          };
        default:
          return normal;
      }
    },
    {
      status: false,
    }
  );

  return { normal, changeNormal };
};

export interface IHomePageProps {
  data: THomePageData;
}

export const HomePage: React.FC<IHomePageProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allHomepage_slides');
  if (!doc) return null;

  const allData = _get(data, ['prismic', 'allHomepage_slides', 'edges']);

  const slidesLabel = extractSlidesLabel(allData);

  const [animations, setAnimStatus] = useState(true);
  const [currIndex, setCurrIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);

  const { normal, changeNormal } = useNormalStatus();

  const slides: any = [];

  const touchStart = { x: 0, y: 0, started: false };
  const noPassive = { passive: false };

  let square = useRef(null);
  let redTyres = useRef(null);
  let redTime = useRef(null);
  let redPrice = useRef(null);

  if (typeof window !== 'undefined' && typeof window.currIndex === 'undefined') {
    window.currIndex = currIndex;
  }

  const isProperTouch = event => {
    if (
      event.srcElement &&
      event.srcElement.classList &&
      (event.srcElement.classList.contains('header__partner-link') ||
        event.srcElement.classList.contains('menu-btn-disable-touch'))
    ) {
      return false;
    }
    return true;
  };

  const goNext = function() {
    if (window.currIndex < slides.length - 1) {
      setPrevIndex(window.currIndex);
      setCurrIndex(window.currIndex + 1);
    }
  };

  const goPrev = function() {
    if (window.currIndex > 0) {
      setPrevIndex(window.currIndex);
      setCurrIndex(window.currIndex - 1);
    }
  };

  const getDirection = function(touchEnd) {
    let direction = 0;

    const diff_x = Math.abs(touchStart.x - touchEnd.x);
    const diff_y = Math.abs(touchStart.y - touchEnd.y);

    // horizontal move
    if (diff_x > diff_y) {
      if (touchStart.x < touchEnd.x) {
        direction = -1;
      } else if (touchStart.x > touchEnd.x) {
        direction = 1;
      }

      // vertical move
    } else if (diff_x < diff_y) {
      if (touchStart.y < touchEnd.y) {
        direction = -1;
      } else if (touchStart.y > touchEnd.y) {
        direction = 1;
      }
    }

    return direction;
  };

  const touchStartEvent = function(event: TouchEvent) {
    if (!isProperTouch(event)) {
      return false;
    }
    const touch = event.changedTouches[0];

    if (
      (typeof window.animation === 'undefined' || window.animation === true) &&
      animations === true
    ) {
      touchStart.x = touch.pageX;
      touchStart.y = touch.pageY;
      touchStart.started = true;
    }
  };

  const touchEndEvent = function(event: TouchEvent) {
    if (!isProperTouch(event)) {
      return false;
    }
    const touch = event.changedTouches[0];

    if (
      (typeof window.animation === 'undefined' || window.animation === true) &&
      animations === true &&
      touchStart.started === true
    ) {
      setAnimStatus(false);
      window.animation = false;

      const x = touch.pageX;
      const y = touch.pageY;

      if (typeof window.currIndex === 'undefined') {
        window.currIndex = currIndex;
      }

      const direction = getDirection({ x: x, y: y });

      if (direction < 0) {
        // go to prev slide
        goPrev();
      } else {
        // go to next slide
        goNext();
      }

      touchStart.x = 0;
      touchStart.y = 0;
      touchStart.started = false;

      // enable move after animations
      setTimeout(() => {
        setAnimStatus(true);
        window.animation = true;
      }, ANIMATION_TIME);
    }
  };

  const wheelEffect = function(e: any) {
    if (
      (typeof window.animation === 'undefined' || window.animation === true) &&
      animations === true
    ) {
      setAnimStatus(false);
      window.animation = false;

      if (typeof window.currIndex === 'undefined') {
        window.currIndex = currIndex;
      }

      const delta = e.wheelDelta ? e.wheelDelta : e.deltaY * -1;

      if (delta < 0) {
        // go to next slide
        if (window.currIndex < slides.length - 1) {
          setPrevIndex(window.currIndex);
          setCurrIndex(window.currIndex + 1);
        }
      } else {
        // go to prev slide
        if (window.currIndex > 0) {
          setPrevIndex(window.currIndex);
          setCurrIndex(window.currIndex - 1);
        }
      }

      // enable move after animations
      setTimeout(() => {
        setAnimStatus(true);
        window.animation = true;
      }, ANIMATION_TIME);
    }
  };

  const redTitleAnimation = function() {
    if (redTyres && redTime && redPrice) {
      const tl = gsap.timeline({
        repeat: -1,
        delay: 3,
      });

      const startDelayOp = 0.3;
      const endDelayOp = 0.3;

      tl.to(redTyres, { 'max-width': '14.5rem', ease: Power3.easeIn, duration: 1, delay: 0.5 });
      tl.to(
        redTyres,
        { opacity: 1, ease: Power3.easeIn, duration: 0.5, delay: startDelayOp },
        '-=1'
      );
      tl.to(redTyres, { 'max-width': '0rem', ease: Power3.easeOut, duration: 1, delay: 0.5 });
      tl.to(
        redTyres,
        { opacity: 0, ease: Power3.easeOut, duration: 0.5, delay: endDelayOp },
        '-=1'
      );

      tl.to(redPrice, { 'max-width': '14.5rem', ease: Power3.easeIn, duration: 1, delay: 0.5 });
      tl.to(
        redPrice,
        { opacity: 1, ease: Power3.easeIn, duration: 0.5, delay: startDelayOp },
        '-=1'
      );
      tl.to(redPrice, { 'max-width': '0rem', ease: Power3.easeOut, duration: 1, delay: 0.5 });
      tl.to(
        redPrice,
        { opacity: 0, ease: Power3.easeOut, duration: 0.5, delay: endDelayOp },
        '-=1'
      );

      tl.to(redTime, { 'max-width': '14.5rem', ease: Power3.easeIn, duration: 1, delay: 0.5 });
      tl.to(
        redTime,
        { opacity: 1, ease: Power3.easeIn, duration: 0.5, delay: startDelayOp },
        '-=1'
      );
      tl.to(redTime, { 'max-width': '0rem', ease: Power3.easeOut, duration: 1, delay: 0.5 });
      tl.to(redTime, { opacity: 0, ease: Power3.easeOut, duration: 0.5, delay: endDelayOp }, '-=1');

      return tl;
    }

    return null;
  };

  const runInitTransition = function() {
    const tlInitTr = gsap.timeline();

    tlInitTr.to('.homepage-loaded-square', {
      keyframes: [
        { left: '-83.5vw', ease: Power3.easeIn, duration: 0.7 },
        { left: '0vw', ease: Power3.easeOut, duration: 0.7, delay: 0 },
        { left: '100vw', ease: Power3.easeOut, duration: 0.7, delay: 0 },
        { left: '-200vw', ease: Power3.easeOut, duration: 0 },
      ],
    });

    tlInitTr.to('.homepage-loaded', { display: 'block' }, '-=0.9');

    if (document.querySelectorAll('.anim-bar').length > 0) {
      tlInitTr.fromTo(
        '.anim-bar',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: Power3.easeIn,
          duration: 1,
        },
        '-=0.5'
      );
    }
  };

  const preventDefault = function(event) {
    event.preventDefault();
  };

  const stopPropagation = function(event) {
    event.stopPropagation();
  };

  useEffect(() => {
    if (typeof window == 'undefined') return;

    let tl: any = null;
    tl = redTitleAnimation();

    window.currIndex = currIndex;

    if (
      slides.length > 0 &&
      typeof slides[currIndex] !== 'undefined' &&
      typeof slides[prevIndex] !== 'undefined'
    ) {
      const currSlide = slides[currIndex];
      const prevSlide = slides[prevIndex];

      gsap.to(currSlide.slide, {
        keyframes: [
          { 'z-index': 1 },
          { left: 0, ease: Power3.easeIn, duration: 0.5, delay: 0.5 },
          { 'z-index': 'auto', delay: 1 },
        ],
      });

      gsap.to(currSlide.letters.current, {
        keyframes: [
          {
            duration: 1,
            delay: 2,
            stagger: { amount: 0.5, ease: Power3.easeIn },
            left: '50%',
            ease: Power3.easeOut,
          },
        ],
      });

      gsap.to(currSlide.image, {
        keyframes: [{ transform: 'scale(1)', ease: Power3.easeOut, duration: 2, delay: 1.5 }],
      });

      if (prevIndex !== currIndex) {
        gsap.to(square, {
          keyframes: [
            { left: 0, ease: Power3.easeIn, duration: 0.5, delay: 0.5 },
            { left: '100vw', ease: Power3.easeOut, duration: 0.5, delay: 0.5 },
            { left: '-100vw', ease: Power3.easeOut, duration: 0 },
          ],
        });

        gsap.to(prevSlide.slide, { keyframes: [{ left: '-100vw', delay: 1 }] });

        gsap.to(prevSlide.letters.current, {
          keyframes: [
            {
              duration: 1,
              stagger: { amount: 1, ease: Power3.easeIn },
              left: '300%',
              ease: Power3.easeIn,
            },
            { duration: 0, stagger: 0, left: '-50%' },
          ],
        });

        gsap.to(prevSlide.image, { duration: 0, delay: 2, transform: 'scale(1.25)' });
      }
    }

    setTimeout(() => {
      if (currIndex > 0) {
        changeNormal({ type: 'WHITE' });
      } else {
        changeNormal({ type: 'BLACK' });
      }
    }, 1000);

    return () => {
      if (typeof tl !== 'undefined' && tl) {
        tl.seek(0).kill();
      }
    };
  }, [currIndex]);

  useEffect(() => {
    if (typeof window == 'undefined') return;

    document.querySelector('body').addEventListener('touchmove', preventDefault, noPassive);
    document.querySelector('.menu').addEventListener('touchmove', stopPropagation, noPassive);

    window.addEventListener('scroll', preventDefault, noPassive);
    window.addEventListener('wheel', wheelEffect, noPassive);
    window.addEventListener('touchstart', touchStartEvent, noPassive);
    window.addEventListener('touchend', touchEndEvent, noPassive);

    return () => {
      document.querySelector('body').removeEventListener('touchmove', preventDefault);
      document.querySelector('.menu').removeEventListener('touchmove', stopPropagation);

      window.removeEventListener('scroll', preventDefault);
      window.removeEventListener('wheel', wheelEffect);
      window.removeEventListener('touchstart', touchStartEvent);
      window.removeEventListener('touchend', touchEndEvent);
    };
  });

  let images_list: string[] = [];
  allData.map(d => {
    images_list.push(d.node.image.url);
  });

  const { imageRefs, waiting } = useWaitingAnimation(images_list, 1000, true);
  const [animated, setAnimated] = useState(!waiting);

  useEffect(() => {
    if (typeof window == 'undefined') return;

    if (waiting === false) {
      runInitTransition();

      setTimeout(() => {
        setAnimated(!waiting);
      }, LOADED_PAGE_DELAY);
    }
  }, [waiting]);

  return (
    <>
      {!animated && <Loader />}

      {images_list.map((image, index) => (
        <WaitForImages key={index} url={image} forwardedRef={imageRefs[index]} />
      ))}

      <div className="homepage-loaded">
        <Header homePage normal={normal.status} />

        <Slides
          slidesLabel={slidesLabel}
          currIndex={currIndex}
          setCurrIndex={setCurrIndex}
          setPrevIndex={setPrevIndex}
          actual={currIndex}
          normal={normal.status}
        />

        <main className="homepage">
          {allData.map((d, index) => {
            return (
              <Fragment key={index}>
                <HomePageLayout
                  data={d}
                  homePage
                  index={index}
                  slides={slides}
                  showMp4Video={index === 0 ? '/oak_homepage_loop.mp4' : undefined}
                >
                  <div className="homepage__text">
                    {d.node.special_first_frame ? (
                      <div className="homepage__first-frame">
                        <div className="homepage__desc">
                          {RichText.asText(d.node.description, linkResolver)}
                        </div>
                        <h2>
                          Right
                          <span className="homepage__words-wrap">
                            <span className="homepage__words">
                              <span ref={el => ((redTyres as any) = el)} className="homepage__word">
                                Tyre
                              </span>
                              <span ref={el => ((redPrice as any) = el)} className="homepage__word">
                                Price
                              </span>
                              <span ref={el => ((redTime as any) = el)} className="homepage__word">
                                Time
                              </span>
                              <span className="homepage__divider">/</span>
                            </span>
                          </span>
                        </h2>
                      </div>
                    ) : (
                      <div className="homepage__other-frame">
                        <h2>{RichText.asText(d.node.heading)}</h2>
                        <div className="homepage__desc">
                          {RichText.render(d.node.description, linkResolver)}
                        </div>
                        <Link className="homepage__link" to={linkResolver(d.node.link._meta)}>
                          <span className="homepage__link-arrow"></span>
                          {RichText.asText(d.node.link_text)}
                        </Link>
                      </div>
                    )}
                  </div>
                </HomePageLayout>
              </Fragment>
            );
          })}

          <div
            className="homepage__square"
            ref={el => {
              (square as any) = el;
            }}
          ></div>
        </main>
        <span
          className={
            currIndex >= slidesLabel.length - 1
              ? 'homepage__next homepage__next--prev'
              : 'homepage__next'
          }
          onClick={() => {
            if (
              (typeof window.animation === 'undefined' || window.animation === true) &&
              animations === true
            ) {
              setAnimStatus(false);
              window.animation = false;
              setPrevIndex(currIndex);
              setCurrIndex(currIndex >= slides.length - 1 ? currIndex - 1 : currIndex + 1);

              setTimeout(() => {
                setAnimStatus(true);
                window.animation = true;
              }, ANIMATION_TIME);
            }
          }}
        ></span>
      </div>
      <div className="homepage-loaded-square"></div>
    </>
  );
};

export const query = graphql`
  query HomePage {
    prismic {
      allHomepage_slides(sortBy: priority_ASC) {
        edges {
          node {
            heading
            description
            large_letters
            priority
            special_first_frame
            link {
              ... on PRISMIC_Company_page {
                _meta {
                  uid
                  type
                }
              }
              ... on PRISMIC_Driven_by_tech {
                _meta {
                  uid
                  type
                }
              }
              ... on PRISMIC_Our_brands_page {
                _meta {
                  uid
                  type
                }
              }
            }
            link_text
            image
          }
        }
      }
    }
  }
`;
export default HomePage;
