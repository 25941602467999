import React, { useRef, useEffect } from 'react';
import _get from 'lodash/get';

import Images from '@components/Images';
import SEO from '@components/SEO';

import { TGatsbyImage } from '@utils/types';

interface IHomePageLayout {
  data: {
    node: {
      image: {
        url: string;
        dimensions: {
          width: string;
          height: string;
        };
      };
      heading: string[];
      large_letters: {
        text: string;
      }[];
    };
  };
  homePage: boolean;
  index: number;
  slides: object;
  showMp4Video?: string;
}

const HomePageLayout: React.FC<IHomePageLayout> = ({
  data,
  homePage,
  index,
  children,
  slides,
  showMp4Video,
}) => {
  const previewImage = _get(data, ['node', 'image']);
  const bck_text = data.node.large_letters[0].text.split('');

  let slide = useRef(null);
  let letters = useRef([]);
  let image = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      slides[index] = {
        slide: slide,
        letters,
        image,
      };
    }
  });

  return (
    <>
      <SEO
        seo={{
          image: previewImage,
        }}
      />
      <div
        className="homepage__layout"
        ref={el => {
          slide = el;
        }}
      >
        {homePage ? (
          <div className="homepage__layout-image">
            <Images
              imageData={[{ image: previewImage }]}
              extractPath="image"
              alt="Home Page"
              classes={{
                base: '',
                extend: '',
                wrap: '',
                class: 'homepage__layout-img',
                video: 'homepage__layout-video',
              }}
              width="1920"
              sizes="100vw"
              clear={true}
              handleRef={el => {
                image = el;
              }}
              showMp4Video={showMp4Video}
            />

            <div className="homepage__layout-noise"></div>
          </div>
        ) : null}
        <div className="lines lines--homepage">
          <span className="lines__line">
            <span
              ref={el => {
                letters.current[0] = el;
              }}
              className="lines__line-letter"
            >
              {typeof bck_text[0] !== 'undefined' ? bck_text[0] : ''}
            </span>
          </span>
          <span className="lines__line">
            <span
              ref={el => {
                letters.current[1] = el;
              }}
              className="lines__line-letter"
            >
              {typeof bck_text[1] !== 'undefined' ? bck_text[1] : ''}
            </span>
          </span>
          <span className="lines__line">
            <span
              ref={el => {
                letters.current[2] = el;
              }}
              className="lines__line-letter"
            >
              {typeof bck_text[2] !== 'undefined' ? bck_text[2] : ''}
            </span>
          </span>
          <span className="lines__line">
            <span
              ref={el => {
                letters.current[3] = el;
              }}
              className="lines__line-letter"
            >
              {typeof bck_text[3] !== 'undefined' ? bck_text[3] : ''}
            </span>
          </span>
          <span className="lines__line">
            <span
              ref={el => {
                letters.current[4] = el;
              }}
              className="lines__line-letter"
            >
              {typeof bck_text[4] !== 'undefined' ? bck_text[4] : ''}
            </span>
          </span>
          <span className="lines__line">
            <span
              ref={el => {
                letters.current[5] = el;
              }}
              className="lines__line-letter"
            >
              {typeof bck_text[5] !== 'undefined' ? bck_text[5] : ''}
            </span>
          </span>
          <span className="lines__line">
            <span
              ref={el => {
                letters.current[6] = el;
              }}
              className="lines__line-letter"
            >
              {typeof bck_text[6] !== 'undefined' ? bck_text[6] : ''}
            </span>
          </span>
        </div>
        <div className="homepage__content">{children}</div>
      </div>
    </>
  );
};

export default HomePageLayout;
