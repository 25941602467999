import React from 'react';

const Loader: React.FC = () => (
  <div className="wa-wrap">
    <div className="wa-block">
      <div className="wa-logo-wrap">
        <div className="wa-logo"></div>
      </div>
      <div className="wa-shadow-wrap">
        <div className="wa-shadow"></div>
      </div>
    </div>
  </div>
);

export default Loader;
